<template>
	<div :class="themeClass" class="width-fill">
		<div class="maink margin-lr-auto bg-fff margin-t-20">
			<breadcrumb></breadcrumb>
			<p class="color-theme font-size16 font-weight700 margin-b-10 margin-t-6">全部商品({{carCount}})</p>
			<div class="">
				<div class="flex-row-space-between-center font-size14" style="height: 35px;background: #F2F4FD;">
					<div class="flex-row-align-center">
						<el-checkbox v-model="checkedAll" @change="All"></el-checkbox>
						<div class="flex-row-align-center">
							<div class="">全选</div>
						</div>
					</div>
					<div class="">商品信息</div>
					<div class="flex-row-space-between-center">
						<p class="width156" style="text-align: center;">数量</p>
						<p class="width156" style="text-align: center;">单价</p>
					</div>
				</div>
				<div class="wrap ">
					<div v-for="(item, index) in Data" :key="index" class="">
						<!-- <div class="flex-row-align-center" style="height: 35px;background: #F2F4FD;">
							<el-checkbox v-model="item.checked" @change="timeCheckedChoose($event, index)"></el-checkbox>
							<div class="font-size14">{{ item.data }}</div>
						</div> -->
						<div v-for="(subItem, subIndex) in item.shoppingCar" :key="subIndex"
							class="flex-row-space-between-center shopCart bg-fff" style="">
							<div class="flex-row-start-center" style="height: 65px;">
								<el-checkbox v-model="subItem.checked"
									@change="goodsCheckedChoose($event, index, subIndex)"></el-checkbox>
								<div class="flex-row-space-between-center" @click="funDetail(subItem)">

									<div class="box-shadow-all imageRadius" style="" @click="funDetail(subItem)">
										<el-image class="imageRadius" :src="subItem.fPicturePath"></el-image>
									</div>
									<div class="margin-l-19" @click="funDetail(subItem)">{{ subItem.fGoodsFullName }}
									</div>
								</div>
							</div>
							<div class="flex-row-align-center" style="height: 65px;">
								<div class="flex-row-center-center width156"
									style="border-left: 1px solid #F2F2F2;height: 80px;">
									<el-input-number size="small" :min="subItem.fMinSaleAmount" :max="99999"
										:precision="subItem.fPrecision" :step="subItem.fAdjustmentRange"
										v-model="subItem.fAmount" @change="valChange($event, index, subIndex,subItem)">
									</el-input-number>
								</div>
								<div class="flex-row-center-center font-color-FF0000 width156"
									style="border-left: 1px solid #F2F2F2;height: 80px;">
									<p class="font-weight700">{{ subItem.fGoodsPrice }}金币</p>
								</div>
							</div>
						</div>
					</div>
					<div class="flex-row-space-between-center bottomBox" style="">
						<div class="flex-row-align-center font-size14 font-color-666" style="width: 40%;">
							<el-checkbox v-model="checkedAll" @change="All"></el-checkbox>
							<div class="flex-row-space-between-center">
								<div class="pointer" @change="All">全选</div>
								<div class="margin-l-17 pointer" @click="deleteOrder">删除选中商品</div>
								<div class="margin-l-17 pointer" @click="collection">移入收藏</div>
							</div>
						</div>
						<div class="flex-row-space-between-center font-size14 font-color-666">
							<div class="flex-row-center-center margin-r-35">
								已选择
								<div class="font-color-FF0000 font-weight700 font-size14 margin-rl-5"> {{ checkCount }}
								</div>
								件商品, 总价：
								<div class="font-color-FF0000 font-weight700 font-size18">{{ checkMoney }}<span
										class="font-size14">金币</span></div>
							</div>
							<button class="btn-to-order bg-color-theme pointer font-color-fff"
								@click="gotoOrder">去结算</button>
						</div>
					</div>
				</div>
			</div>
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import parm from '@/components/loyout/publicList'
	import GoldMallHeader from '@/components/GoldMall/GoldMallHeader'
	import breadcrumb from '@/components/breadcrumb.vue';
	export default {
		components: {
			GoldMallHeader,
			breadcrumb
		},
		data() {
			return {
				search: '', //搜索框文字
				leftItemShow: false,
				numberbox: 0,
				checkList: [],
				checkMoney: 0,
				checkCount: 0, //选中的商品
				checkedAll: false,
				Data: [],
				navItems: parm.navItems,
				// 购物车初始化请求数据
				requestParam: {
					"maxResultCount": 10,
					"filter": "",
					"sorting": "",
					"skipCount": 0
				},
				carCount: 0,
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		created() {
			this.getDataList();
		},
		methods: {
			//点击跳转相应商品详情页
			funDetail(item) {
				let goodId = item.fGoodsID
				this.$router.push({
					path: '/goldMall/goodsDetails',
					query: {
						id: goodId
					}
				})
			},

			// 获取购物车商品数量
			getCarCount() {
				this.ApiRequestPost('api/mall/ebsale/shopping-cart/get-gold-coin-shopping-cart-count', {}).then(
					result => {
						this.carCount = result.obj.number
						this.carCount = 0 < this.carCount < 10 ? '0' + this.carCount : this.carCount
						console.log(this.carCount);
					},
					error => {}
				);
			},
			collection() {
				this.$message({
					type: 'success',
					message: '加入收藏!'
				});
			},
			// 删除选中的商品
			deleteOrder() {
				// 判断是否有选中的
				let arr = []
				this.Data.forEach(item => {
					item.shoppingCar.forEach(items => {
						if (items.checked == true) {
							arr.push(items)
						}
					})
				})
				if (arr.length == 0) {
					this.$message({
						message: '没有可删除项,请选择',
						type: 'warning'
					});
					return
				}
				let deleteParams = []
				console.log("删除");
				this.Data.forEach(item => {
					item.shoppingCar.forEach(items => {
						if (items.checked == true) {
							deleteParams.push({
								fShoppingCartID: items.fShoppingCartID,
								frv: items.frv,
								rowStatus: 8
							})
						}
					})
				})
				this.ApiRequestPost('api/mall/ebsale/shopping-cart/delete-shopping-cart', deleteParams).then(
					result => {
						console.log(result);
						this.getDataList()
					},
					error => {}
				);
			},
			getDataList() {
				this.ApiRequestPost('api/mall/ebsale/shopping-cart/get-gold-coin-shpping-cart', this.requestParam).then(
					result => {
						console.log(result);
						result.obj.items.forEach(item => {
							item.checked = false
							item.shoppingCar.forEach(items => {
								items.checked = false
							})
						});
						this.Data = result.obj.items
						this.checkMoney = 0;
						this.checkCount = 0;
						this.getCarCount()
					},
					error => {}
				);
			},
			/* 判断是否全选 */
			isCheckedAll() {
				let n = 0
				this.Data.forEach(item => {
					if (item.checked == true) {
						n++
					}
				})
				if (this.Data.length == n) {
					this.checkedAll = true;
				} else {
					this.checkedAll = false;
				}
				console.log(this.checkedAll);
			},
			/* 全选 */
			All(event) {
				this.Data.forEach(item => {
					item.checked = event
					item.shoppingCar.forEach(items => {
						items.checked = event
					})
				})
				this.moneyTotal()
			},
			//同一时间段全选
			timeCheckedChoose(event, index) {
				this.Data[index].checked = event;
				for (let item of this.Data[index].shoppingCar) {
					item.checked = event;
				}
				this.isCheckedAll();
				this.moneyTotal();
			},
			//商品选中事件
			goodsCheckedChoose(event, index, subIndex) {
				console.log(event, index, subIndex);
				/* 确定当前多选的状态如果有任何一个商品是false,那么整体的状态必然是false,如果所有的商品都是true,那么整体就是true */
				this.Data[index].shoppingCar[subIndex].checked = event;
				let a = 0
				for (let item of this.Data[index].shoppingCar) {
					console.log(item.checked);
					if (item.checked == true) {
						a++
					}
				}
				console.log(a);
				console.log(this.Data[index].shoppingCar);
				if (this.Data[index].shoppingCar.length == a) {
					console.log('all');
					this.Data[index].checked = true
				} else {
					this.Data[index].checked = false
				}
				this.isCheckedAll();
				this.moneyTotal();
			},
			//数量选择
			valChange(val, index, subIndex, subItem) {
				if (!val) {
					this.Data[index].shoppingCar[subIndex].fAmount =0
					return
				} else {
					this.Data[index].shoppingCar[subIndex].fAmount = val;
				}
				this.moneyTotal();
				this.ApiRequestPutNOMess('api/mall/ebsale/shopping-cart/update-shopping-cart', {
					fShoppingCartID: subItem.fShoppingCartID,
					fAmount: subItem.fAmount
				}).then(
					result => {
						console.log(result);
						this.moneyTotal()
					},
					error => {}
				);
			},
			/* 获取总金额和选择的商品数量 */
			moneyTotal() {
				var tempMoney = 0;
				var tempLength = 0;
				this.Data.forEach(item => {
					item.shoppingCar.forEach(items => {
						if (items.checked == true) {
							if (item.activityPrice != -1) {
								tempMoney += items.activityPrice * items.fAmount;
							} else {
								tempMoney += items.fGoodsPrice * items.fAmount;
							}
							tempLength++;
						}
					})
				})
				this.checkMoney = tempMoney;
				this.checkCount = tempLength;
			},
			//去结算
			gotoOrder() {
				console.log('this.Data', this.Data);
				let chooseList = [];
				this.Data.forEach((item) => {
					item.shoppingCar.forEach((childitem) => {
						if (childitem.checked) {
							chooseList.push(childitem);

						}
					});
				});
				if (chooseList.length == 0) {
					this.$message('请选中商品再去结算哦');
					// uni.showToast({
					//     title: '标题',
					//     duration: 2000
					// });
				} else {
					this.$store.commit("changeCommonData", chooseList);
					this.$router.push({
						name: 'FillOrderGoldMall',
						params: {}
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.btn-to-order {
		width: 120px;
		height: 40px;
		margin-right: 20px;
		border: none;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.bg-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	/* 面包屑CSS */
	.breadcrumb {
		margin-top: 15px;
		background-color: white;
	}

	/* 面包屑CSS END */

	::v-deep .el-checkbox {
		padding: 0 10px !important;
	}

	// 购物车
	.wrap {
		width: 100%;
		margin-bottom: 40px;
		margin-top: 9px;
	}

	// 下方按钮
	/* 新的购物车样式 */

	.margin-l-17 {
		margin-left: 17px;
	}

	.shopCart {
		margin: 10px 0px;
		// padding: 25px 0px;
		border: 1px solid #f2f2f2;
		height: 116px;
	}

	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.width156 {
		width: 156px;
	}

	.bottomBox {
		height: 70px;
		background: #f8f9fe;
		width: 100%;
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed('themes_color');
			border-color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox-group {
		font-size: 14px;
	}

	::v-deep .el-checkbox__label {
		font-size: 0px;
	}
</style>
